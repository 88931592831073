import React from "react";
import { graphql } from "gatsby";

import { Accordion, SEO } from "../components";
import withLayout from "../hoc/withLayout/withLayout";

interface FAQ {
  question: string;
  answer: string;
  priority: number;
}

const FAQEntry = ({ question, answer, priority }: FAQ) => {
  return {
    title: question,
    content: answer,
    priority,
  };
};

const FAQs = ({
  data: {
    alamedaapi: { faqPage },
  },
}) => {
  if (!faqPage) return null;
  return (
    <>
      <SEO title="FAQs" />
      <div className="component-default-padding">
        <h1 className="h1-styles text-center text-primary-color ">FAQs</h1>
        {faqPage.sections.map((section, i) => (
          <div key={i}>
            <div className="prose-x mb-4 mt-8 md:mb-5 md:mt-16">
              <h2 className="text-lg lg:text-3xl">{section.primary}</h2>
            </div>
            <Accordion
              items={section.faqs
                .map(FAQEntry)
                .sort((a, b) => a.priority - b.priority)}
              icon="sign"
              contentClassName="prose-xl"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default withLayout(FAQs);

export const faqsQuery = graphql`
  query FAQS_QUERY($locale: String!) {
    alamedaapi {
      faqPage(locale: $locale) {
        sections {
          primary
          faqs {
            question
            answer
            priority
          }
        }
      }
    }
  }
`;
